import {get, post} from '../axios'

// 系统日志-列表
// export const logList = params => get('/web/system/log/list',params);

// 系统日志-清空

// export const logDelete = params => post('/web/system/log/delete',params);

export const sysLog = params => get('/system/web/sysLog/list',params);

export const sysLogView = params => get(`/system/web/sysLog/view/${params}`);
