<template>
  <div>
    <el-drawer
      :with-header="false"
      :size="1000"
      :visible.sync="drawer"
      :before-close="handleClose"
    >
      <div>
        <div class="head">
          <div v-if="info.imgUrl" style="display: flex">
            <div
              class="demo-image__preview"
              v-for="(item, index) in info.imgUrl.split(',')"
              style="margin-right: 20px"
              :key="index"
            >
              <el-image
                style="width: 100px; height: 100px"
                :src="item"
                :preview-src-list="[item]"
              />
            </div>
          </div>
          <div class="section">
            <!-- <div class="title">用户信息</div> -->

            <ul class="list">
              <li class="item">
                <div class="item-title">创建时间：</div>
                <div class="value">{{ info.createTime }}</div>
              </li>
              <li class="item">
                <div class="item-title">操作地址：</div>
                <div class="value">{{ info.ip }}</div>
              </li>
  

              <li class="item">
                <div class="item-title">操作类型：</div>
                <div class="value">
                  {{
                    info.operateType == 1
                      ? "新增"
                      :info.operateType == 2
                      ? "修改"
                      :info.operateType == 3
                      ? "删除"
                      :info.operateType == 4
                      ? "导出"
                      : "导入"
                  }}
                </div>
              </li>

              <li class="item">
                <div class="item-title">请求方式：</div>
                <div class="value">
                  {{ info.requestMethod }}
                </div>
              </li>
    
              <li class="item">
                <div class="item-title">操作状态：</div>
                <div class="value">
                  {{ info.state == 1 ? "成功" : "失败" }}
                </div>
              </li>
              <li class="item">
                <div class="item-title">店铺id：</div>
                <div class="value">
                  {{ info.storeId }}
                </div>
              </li>

              <li class="item">
                <div class="item-title">操作角色类型：</div>
                <div class="value">
                  {{
                    info.type == 1
                      ? "平台"
                      :info.type == 2
                      ? "门店"
                      :'供应商'
                  }}
                </div>
              </li>
              <li class="item">
                <div class="item-title">请求地址：</div>
                <div class="value">
                  {{ info.url }}
                </div>
              </li>
              <li class="item">
                <div class="item-title">操作人姓名：</div>
                <div class="value">
                  {{ info.username }}
                </div>
              </li>
              <li class="item" style="width: 100%">
                <div
                  class="item-title"
                  style="height: 100%; padding-top: 10px; margin: 0"
                >
                操作菜单：
                </div>
                <div
                  class="value"
                  style="width: calc(100% - 100px); line-height: 20px"
                >
                  {{ info.menu }}
                </div>
              </li>
              <li class="item" style="width: 100%">
                <div
                  class="item-title"
                  style="height: 100%; padding-top: 10px; margin: 0"
                >
                id：
                </div>
                <div
                  class="value"
                  style="width: calc(100% - 100px); line-height: 20px"
                >
                  {{ info.id }}
                </div>
              </li>
              <li class="item" style="width: 100%">
                <div
                  class="item-title"
                  style="height: 100%; padding-top: 10px; margin: 0"
                >
                操作方法：
                </div>
                <div
                  class="value"
                  style="width: calc(100% - 100px); line-height: 20px"
                >
                  {{ info.method }}
                </div>
              </li>

              <li class="item" style="width: 100%">
                <div
                  class="item-title"
                  style="height: 100%; padding-top: 10px; margin: 0"
                >
                请求参数：
                </div>
                <div
                  class="value"
                  style="width: calc(100% - 100px); line-height: 20px"
                >
                  {{ info.requestParams }}
                </div>
              </li>

              <li class="item" style="width: 100%">
                <div
                  class="item-title"
                  style="height: 100%; padding-top: 10px; margin: 0"
                >
                返回参数：
                </div>
                <div
                  class="value"
                  style="width: calc(100% - 100px); line-height: 20px"
                >
                  {{ info.responseParams }}
                </div>
              </li>



<!-- 
              <li class="item" style="width: 100%">
                <div
                  class="item-title"
                  style="height: 100%; padding-top: 10px; margin: 0"
                >
                  描述：
                </div>
                <div
                  class="value"
                  style="width: calc(100% - 100px); line-height: 20px"
                >
                  {{ info.content }}
                </div>
              </li> -->
            </ul>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
export default {
  name: "LogDetails",
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
    info: {
      type: Object,
      default: true,
    },
  },
  data() {
    return {
      moren: require("@/assets/images/f.png"),
    };
  },
  mounted() {},
  methods: {
    handleClose() {
      this.$emit("closeDrawer");
    },
  },
};
</script>

<style scoped lang="scss">
.head {
  padding: 20px 35px;
  .full {
    display: flex;
    align-items: center;
    .order_icon {
      width: 60px;
      height: 60px;
    }
    .iconfont {
      color: var(--prev-color-primary);
      &.sale-after {
        color: #90add5;
      }
    }
    .text {
      align-self: center;
      flex: 1;
      min-width: 0;
      padding-left: 12px;
      font-size: 13px;
      color: #606266;
      .title {
        margin-bottom: 10px;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        font-weight: bold;
        color: #282828;
      }
      .order-num {
        padding-top: 10px;
        white-space: nowrap;
      }
    }
  }
  .list {
    display: flex;
    margin-top: 20px;
    overflow: hidden;
    list-style: none;
    padding: 0;
    .item {
      flex: none;
      width: 33%;
      font-size: 14px;
      line-height: 14px;
      color: rgba(0, 0, 0, 0.85);
      .title {
        margin-bottom: 12px;
        font-size: 13px;
        line-height: 13px;
        color: #666666;
      }
    }
  }
}
.tabNumWidth {
  max-height: 350px;
  overflow-y: auto;
  &:before {
    display: none;
  }
}
.el-tabs--border-card {
  box-shadow: none;
  border-bottom: none;
}
.section {
  .title {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 15px;
    color: #303133;
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .item {
    flex: 0 0 calc(100% / 3);
    display: flex;
    margin-top: 16px;
    font-size: 13px;
    color: #606266;
    align-items: center;
    .item-title {
      width: 100px;
      text-align: right;
      margin: 10px;
      margin-left: 0;
    }
  }
  .item100 {
    padding-left: 0;
    flex: 0 0 calc(100% / 1);
    padding-left: 0 !important;
  }
  .contentPic {
    width: 500px;
    margin: 0 auto;
    max-height: 600px;
    overflow-y: auto;
    /deep/img {
      max-width: 100%;
    }
  }
  .value {
    // flex: 1;
    word-break: break-all;
    .value-item {
      &::after {
        content: "/";
        display: inline-block;
      }
      &:last-child {
        &::after {
          display: none;
        }
      }
    }
    .value-temp {
      &::after {
        content: "、";
        display: inline-block;
      }
      &:last-child {
        &::after {
          display: none;
        }
      }
    }
    image {
      display: inline-block;
      width: 40px;
      height: 40px;
      margin: 0 12px 12px 0;
      vertical-align: middle;
    }
  }
}
.tab {
  display: flex;
  align-items: center;
  .el-image {
    width: 36px;
    height: 36px;
    margin-right: 10px;
  }
}
/deep/.el-drawer__body {
  overflow: auto;
}

/deep/.ones th {
  background: #f0f5ff;
}
</style>
