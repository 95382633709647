<template>
  <div class="divBox">
    <div class="selCard">
      <el-form
        :model="tableFrom"
        ref="searchForm"
        inline
        size="small"
        label-width="100px"
      >
        <div>
          <el-form-item label="操作人姓名：" prop="username">
            <el-input
              placeholder="请输入操作人姓名"
              v-model="tableFrom.username"
              class="input-with-select selWidth"
              clearable
            >
            </el-input>
          </el-form-item>
          <el-form-item label="时间：" prop="time">
            <el-date-picker
              v-model="tableFrom.time"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" size="small" @click="search()"
              >搜索</el-button
            >
            <el-button size="small" @click="searchReset()">重置</el-button>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <el-card class="mt14">
      <el-table
        v-loading="listLoading"
        :data="tableData.data"
        size="small"
        highlight-current-row
      >
       <el-table-column
          type="index"
          :index="getTabelIndex"
          width="100" 
          label="序号"
        />
        <el-table-column
          prop="affiliationName"
          label="所属组织名称"
          min-width="120"
        />
        <el-table-column prop="createTime" label="创建时间" min-width="120" />
        <el-table-column prop="ip" label="操作地址" min-width="120" />
        <el-table-column prop="menu" label="操作菜单" min-width="120" />
        <!-- <el-table-column prop="method" label="操作方法" min-width="120" /> -->
        <el-table-column prop="operateType" label="操作类型" min-width="120">
          <template slot-scope="scope">
            <div>
              {{
                scope.row.operateType == 1
                  ? "新增"
                  : scope.row.operateType == 2
                  ? "修改"
                  : scope.row.operateType == 3
                  ? "删除"
                  : scope.row.operateType == 4
                  ? "导出"
                  : "导入"
              }}
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="requestMethod" label="请求方式" min-width="120" />
        <el-table-column prop="requestParams" label="请求参数" min-width="120" />
        <el-table-column prop="responseParams" label="返回参数" min-width="120" /> -->
        <el-table-column prop="state" label="操作状态" min-width="120">
          <template slot-scope="scope">
            <div>{{ scope.row.state == 1 ? "成功" : "失败" }}</div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="storeId" label="店铺id" min-width="120" /> -->
        <el-table-column prop="type" label="操作角色类型" min-width="120">
          <template slot-scope="scope">
            <div>
              {{
                scope.row.type == 1
                  ? "平台"
                  : scope.row.type == 2
                  ? "门店"
                  : "供应商"
              }}
            </div>
          </template>
        </el-table-column>
        <!-- <el-table-column prop="url" label="请求地址" min-width="120" />
        <el-table-column prop="userId" label="操作人id" min-width="120" /> -->
        <el-table-column prop="username" label="操作人姓名" min-width="120" />
        <el-table-column
          label="操作"
          min-width="50"
          align="center"
          fixed="right"
        >
          <template slot-scope="scope">
            <!--<el-button type="text" size="small" @click="onEdit(scope.row.feedback_id)">备注</el-button>-->
            <el-button type="text"   icon="el-icon-info" size="small" @click="onDetails(scope.row)"
              >详情</el-button
            >
            <!-- <el-button
                v-if="scope.row.status != 1"
                type="text"
                size="small"
                @click="handleReply(scope.row.feedback_id)"
              >回复</el-button> -->
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          background
          :page-size="tableFrom.pageSize"
          :current-page="tableFrom.pageNum"
          layout="total, prev, pager, next, jumper"
          :total="tableData.total"
          @size-change="handleSizeChange"
          @current-change="pageChange"
        />
      </div>
    </el-card>
    <!--反馈详情-->
    <LogDetails
      @closeDrawer="closeDrawer"
      :drawer="drawer"
      :info="info"
      ref="FeedbackDetails"
    />
  </div>
</template>
<script>
import { sysLog, sysLogView } from "@/api/system/log";
import LogDetails from "./components/logDetails";
import FormImgUpload from "@/components/formComponents/formImgUpload";
import GoodsCard from "./components/goodsCard";
import {getIndex} from "@/utils/other";
import moment from "moment";
export default {
  name: "banner",
  components: {
    FormImgUpload,
    GoodsCard,
    LogDetails,
  },
  data() {
    return {
      title: "新增banner",
      tableFrom: {
        pageNum: 1,
        pageSize: 20,
        status: "1",
        storeId: "",
        time: [],
      },
      storeList: {},
      form: {
        image: [],
        storeId: "",
      },
      listLoading: false,
      tableData: {
        data: [{}],
        total: 0,
      },
      dialogVisible: false,
      dialogVisibleLink: false,
      drawer: false,
      info: {},
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getTabelIndex(index) {
      return getIndex(index,this.tableFrom.pageNum,this.tableFrom.pageSize)
    },
    search(){
     this.tableFrom.pageNum=1
     this.getList()
    },
    uploadDelete() {},
    uploadBefore(file, done, loading, column) {
      if (file.type === "" || column.accept.indexOf(file.type) < 0) {
        this.$message.error("文件格式有误");
        loading();
      } else {
        done();
      }
    },
    uploadError(error, column) {
      this.$message.error(error);
    },
    uploadAfter(res, done) {
      done();
    },
    upload(e) {
      console.log("123", e);
      if (Array.isArray(e)) {
        this.form.image = [...e];
      } else {
        this.form.image = [...this.form.image, e];
      }
    },
    closeDrawer() {
      this.drawer = false;
    },
    searchReset() {
      this.$refs.searchForm.resetFields();
      this.tableFrom.time = [];
      this.getList();
    },
    getList() {
      this.listLoading = true;
      if (this.tableFrom.time && this.tableFrom.time.length > 0) {
        this.tableFrom.startTime = moment(this.tableFrom.time[0]).format(
          "YYYY-MM-DD HH:mm:ss"
        );
        this.tableFrom.endTime =
          moment(this.tableFrom.time[1]).format("YYYY-MM-DD") + " 23:59:59";
      } else {
        this.tableFrom.startTime = "";
        this.tableFrom.endTime = "";
      }
      sysLog({ ...this.tableFrom, time: "" })
        .then((res) => {
          this.tableData.data = res.data.records;
          this.tableData.total = res.data.total;
          this.listLoading = false;
        })
        .catch((res) => {
          this.listLoading = false;
        });
    },

    pageChange(page) {
      this.tableFrom.pageNum = page;
      this.getList();
    },
    handleSizeChange(val) {
      this.tableFrom.pageSize = val;
      this.getList();
    },
    storeChange() {
      this.form.aboutId = "";
      this.form.aboutName = "";
    },
    getGoodsItem(row) {
      this.form.aboutId = row.id;
      this.form.aboutName = row.name;
      this.dialogVisibleLink = false;
    },
    formSubmit() {},
    add() {
      this.dialogVisible = true;
    },
    // 详情
    onDetails(row) {
      sysLogView(row.id).then((res) => {
        this.info = res.data;
        this.drawer = true;
      });
    },
    // 编辑
    onEdit(row) {
      this.form = JSON.parse(JSON.stringify(row));
      this.form.image = [this.form.imgUrl];
      this.form.type = this.form.type.toString();
      this.dialogVisible = true;
    },
    handleClose() {
      this.$refs.form.resetFields();
      this.form.image = [];
      this.form.status = 0;
      this.dialogVisible = false;
    },
    handleCloseLink() {
      this.dialogVisibleLink = false;
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.form.type == 2 && !this.form.aboutName) {
            this.$message.error("请选择商品");
            return;
          }
          if (this.form.type == 3 && !this.form.url) {
            this.$message.error("请输入外部链接");
            return;
          }
          if (this.form.id) {
            bannerUp({
              ...this.form,
              imgUrl: this.form.image.length > 0 ? this.form.image[0] : "",
            }).then((res) => {
              // 获取数据成功后的其他操作
              // console.log(res);
              if (res.code === 200) {
                this.$message.success("修改成功");
                this.getList();
                this.handleClose();
                // done();
              } else {
                this.$message.error(res.msg);
                // done();
              }
            });
          } else {
            bannerAdd({
              ...this.form,
              imgUrl: this.form.image.length > 0 ? this.form.image[0] : "",
            }).then((res) => {
              // 获取数据成功后的其他操作
              // console.log(res);
              if (res.code === 200) {
                this.$message.success(res.msg);
                this.getList();
                this.handleClose();
                // done();
              } else {
                this.$message.error(res.msg);
                // done();
              }
            });
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
  },
};
</script>
  
<style lang="scss" scoped>
.changeLink {
  width: 200px;
  height: 30px;
  border: 1px solid #dcdfe6;
  line-height: 30px;
  font-size: 14px;
  padding-left: 14px;
  box-sizing: border-box;
  cursor: pointer;
}
.inputWidth {
  width: 300px;
}
.inputBox {
  width: 100%;
  display: flex;
  align-items: center;
}
</style>
  